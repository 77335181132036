import React, { useContext, useEffect } from "react";
import "../styles/pages/projectTemplate.scss";
import CTA from "../components/cta";
import { useCheckScreenSize } from "../lib/checkScreenSize";
import ReactContext from "../context/reactContext";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

const ProjectTemplate = ({ pageContext }) => {
  const { project, displayImageData, logoImageData, logoDarkImageData } =
    pageContext;
  const isMobile = useCheckScreenSize(600);
  const {
    liveLink,
    github,
    myRole,
    techStack,
    id,
    logo,
    logoDark,
    title,
    projectName,
    image,
    pageLink,
    description,
    dates,
    team,
    video,
  } = project || {};
  const { theme, setCurrentProject } = useContext(ReactContext);
  const whichImage =
    theme && logoDarkImageData ? logoDarkImageData : logoImageData;
  const whichAlt = theme && logoDark ? logoDark : logo;

  useEffect(() => {
    setCurrentProject(project);
  },[]);

  return (
    <>
      {/* <EntryPage text={projectName} /> */}
      <div className="db-project">
        <section className="db-project__intro">
          <div className="db-project__intro__inner">
            <div className="db-project__intro__image">
              <GatsbyImage image={whichImage} alt={whichAlt} />
            </div>
            <div className="db-project__intro__info-index">
              {isMobile && (
                <div className="db-project__intro__info-index__items-left">
                  <div className="db-project__intro__info-index__item">
                    <h6>Role / Services</h6>
                    <p>{title}</p>
                  </div>
                  <div className="db-project__intro__info-index__item">
                    <h6>Date</h6>
                    <p>{dates}</p>
                  </div>
                  {team && (
                    <div className="db-project__intro__info-index__item">
                      <h6>Team</h6>

                      {team.map((teamMember) => (
                        <p key={teamMember}>{teamMember}</p>
                      ))}
                    </div>
                  )}
                </div>
              )}
              <div className="db-project__intro__info-index__items-right">
                <div className="db-project__intro__info-index__item">
                  <h6>Description</h6>
                  <p>{description}</p>
                </div>
                {liveLink && (
                  <div className="db-project__intro__info-index__cta">
                    <CTA isOutbound to={liveLink}>
                      Live
                    </CTA>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="db-project__image">
          <div className="db-project__image__inner">
            <GatsbyImage alt={image} image={displayImageData} />
          </div>
        </section>
        <section className="db-project__overview">
          <div className="db-project__overview__inner">
            <ul className="db-project__overview__index">
              {myRole.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
        </section>
        {video && (
          <section className="db-project__video">
            <div className="db-project__video__inner">
              <div className="db-project__video__wrapper">
                <StaticImage
                  src="../images/macbookOverlay.png"
                  alt="macbook-overlay"
                  objectFit="cover"
                  objectPosition="50% 50%"
                  height={703}
                  width={1200}
                />
                <video autoPlay muted loop preload="metadata">
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </section>
        )}
        <section className="db-project__tech-stack"></section>
      </div>
    </>
  );
};

export default ProjectTemplate;
